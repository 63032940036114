import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectors as authSelectors } from 'src/features/auth/authSlice';
import { configVars } from 'src/helpers';
import { FeatureFlagKey } from 'src/ts/enums';
import userflow from 'userflow.js';
import { useFeatureFlag } from '../useFeatureFlag';

const USERFLOW_TOKEN = configVars.userflow.token;

const useUserflow = () => {
  const userflowEnabled = useFeatureFlag(FeatureFlagKey.USERFLOW);
  const userData = useSelector(authSelectors.user);
  const identityVerificationEnabled = useSelector(authSelectors.identityVerificationEnabled);
  const incomeVerificationEnabled = useFeatureFlag(FeatureFlagKey.INCOME_VERIFICATION);

  useEffect(() => {
    if (userflowEnabled && userData?.id) {
      userflow.init(USERFLOW_TOKEN);
      userflow.identify(userData.id, {
        role: userData.role,
        identityVerificationEnabled,
        incomeVerificationEnabled: `${!!incomeVerificationEnabled}`
      });
    }
  }, [userflowEnabled, identityVerificationEnabled, incomeVerificationEnabled, userData]);

  return userflow;
};

export default useUserflow;
